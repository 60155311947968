const moment = require('moment');
/**
 * 単純なサンプル
 * moment.jsを使って現在日付をconsole出力するだけ
 */
$(function () {
	// console.log(moment().format("YYYY-MM-DD"));
	$(document).on("click", '#courseClose', function () {
		$("#courseBox").fadeOut()
	})
});
