// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性
import Swiper from 'swiper';
// import Swiper styles
'use strict';

const Tabs = {
	init() {
		let promise = $.Deferred();
		this.$tabs = $('ul.about__feature__tabs');
		this.checkHash();
		this.bindEvents();
		this.onLoad();
		return promise;
	},

	checkHash() {
		if (window.location.hash) {
			this.toggleTab(window.location.hash);
		}
	},

	toggleTab(tab) {
		// targets
		var $paneToHide = $(tab).closest('.about__feature').find('div.about__feature__item').filter('.active'),
			$paneToShow = $(tab),
			$tab = this.$tabs.find('a[href="' + tab + '"]');

		// toggle active tab
		$tab.closest('li').addClass('active').siblings('li').removeClass('active');

		// toggle active tab content
		$paneToHide.removeClass('active').addClass('animating exiting');
		$paneToShow.addClass('animating active');
	},

	showContent(tab) {

	},

	animationEnd(e) {
		$(e.target).removeClass('animating exiting');
	},

	tabClicked(e) {
		e.preventDefault();
		this.toggleTab(e.target.hash);
	},

	bindEvents() {
		// show/hide the tab content when clicking the tab button
		this.$tabs.on('click', 'a', this.tabClicked.bind(this));

		// handle animation end
		$('div.about__feature__item').on('transitionend webkitTransitionEnd', this.animationEnd.bind(this));
	},

	onLoad() {
		$(window).load(function () {
			$('div.about__feature__item').removeClass('is-animating is-exiting');
		});
	}
}
if ($(".about__feature__tabs").length > 0) {
	Tabs.init();
}



if ($(".swiper-container").length > 0) {
	var swiper;
	$(window).on('load resize', function () {
		var w = $(window).width();
		if (w <= 768) {
			if (swiper) {
				return;
			} else {
				swiper = new Swiper('.swiper-container', {
					slidesPerView: 'auto',
					centeredSlides: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
				});
			}
		} else {
			if (swiper) {
				swiper.destroy();
				swiper = undefined;
			}
		}
	});
}

$(document).on('click', '.nav__btn', function () {
	$('.nav__btn,.nav__l').toggleClass('active')
})
